import {Injectable} from '@angular/core';
import {ApiService} from '../../../../shared/service/api.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {EventPageItem} from './event-page-item';
import {EventData} from './event';
import {Observable} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {EventUniqueFields} from './event-unique-fields';
import {EventFilterParam} from './menu-item/event-menu-item.component';
import {PaginatedRecord} from '../../../../shared/menu-item/abstract-filter-menu-item.component';

@Injectable()
export class EventService extends ApiService<EventPageItem, EventData> {
    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    public eventFilterPaginated(filterParams: EventFilterParam): Observable<PaginatedRecord<EventData>> {
        const params = this.replaceIpWithSubnet(this.getHttpParamsFromFilter(filterParams));
        return this.httpClient.get<PaginatedRecord<EventData>>(`${environment.API_ROOT_PATH}events_filter_paginated`, { params });
    }

    public eventFilterParameters(filterParams: EventFilterParam): Observable<EventUniqueFields> {
        const params = new HttpParams()
            .set('date_from', filterParams.date_from)
            .set('date_to', filterParams.date_to)
        return this.httpClient.get<EventUniqueFields>(`${environment.API_ROOT_PATH}events_fields_light`, {params});
    }

    public downloadEventReport(filterParams: EventFilterParam) {
        const params = this.replaceIpWithSubnet(this.getHttpParamsFromFilter(filterParams));
        return this.httpClient.get(`${environment.API_ROOT_PATH}events_filter_paginated_download`, {params, responseType: 'blob'});
    }

    protected resourcePath(): string {
        return 'event';
    }

    private replaceIpWithSubnet(params: HttpParams): HttpParams {
        const paramValue = params.get('ip');
        if (paramValue && paramValue.includes('/')) {
            params = params.append('subnet', paramValue);
            params = params.delete('ip');
        }
        return params;
    }
}
