import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';
import {AccessDeniedComponent} from './shared/component/access-denied/access-denied.component';
import {MenuService} from './console/menu/menu.service';
import {StatusPipe} from './shared/pipe/status.pipe';
import {SecurityLinkService} from './menu-item/security/security-link/security-link.service';
import {AnonymousGuardService} from './mt-security/anonymous.guard.service';
import {WhiteListService} from './menu-item/lists/white-list/white-list.service';
import {ErrorInterceptor} from './mt-security/error-interceptor';
import {ConfirmationService} from 'primeng/api';
import {UserService} from './menu-item/security/user/user.service';
import {AuthGuardService} from './mt-security/auth.guard.service';
import {BlackListService} from './menu-item/lists/black-list-unit/black-list/black-list.service';
import {BasicAuthInterceptor} from './mt-security/basic-auth-interceptor';
import {RoleService} from './menu-item/security/role/role.service';
import {GeoService} from './menu-item/lists/geo/geo.service';
import {BlockUiService} from './shared/service/block.ui.service';
import {AuthenticationService} from './mt-security/authentication.service';
import {ApplicationService} from './menu-item/application/application.service';
import {CampaignService} from './menu-item/campaign/campaign.service';
import {OfferService} from './menu-item/offer/offer.service';
import {DatePipe} from './shared/pipe/date.pipe';
import {AccessControlListService} from './menu-item/security/access-control-list/access-control-list.service';
import {PostbackRouteRuleService} from './menu-item/postback/postback-route-rule/postback-route-rule.service';
import {WebsiteService} from './menu-item/website/website.service';
import {PushTemplateService} from './menu-item/push-configuration/push-template/push-template.service';
import {PushScheduleTemplateService} from './menu-item/push-configuration/push-schedule-template/push-schedule-template.service';
import {PushService} from './menu-item/push-configuration/push/push.service';
import {AccessLogService} from './menu-item/security/access-log/access-log.service';
import {SecurityContext} from './mt-security/security-context';
import {ResourceMenuViewAccessDecisionManager} from './mt-security/resource-menu-view-access-decision-manager';
import {PostbackRoutingLogService} from './menu-item/postback/postback-routing-log/postback-routing-log.service';
import {PostbackLogService} from './menu-item/postback/postback-log/postback-log.service';
import {AccessGuardService} from './mt-security/access.guard.service';
import {RouteNameToResourceConverter} from './mt-security/route-name-to-resource-converter';
import {IdBlackListService} from './menu-item/lists/black-list-unit/id-black-list/id-black-list.service';
import {ClickService} from './menu-item/clicks-events/click/app-click/click.service';
import {EventService} from './menu-item/clicks-events/event/app-event/event.service';
import {VersionService} from './shared/service/version.service';
import {SettingService} from './menu-item/settings/setting.service';
import {PushSetService} from './menu-item/push-configuration/push-set/push-set.service';
import {DropdownModule} from 'primeng/dropdown';
import {CalendarModule} from 'primeng/calendar';
import {DomainService} from './shared/service/domain.service';

@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        DropdownModule,
        CalendarModule,
    ],
    declarations: [
        AppComponent,
        AccessDeniedComponent,
        // DateFilterComponent
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        MenuService,
        ConfirmationService,
        AuthenticationService,
        {provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
        AuthGuardService,
        AnonymousGuardService,
        AccessGuardService,
        RouteNameToResourceConverter,
        BlockUiService,
        SecurityContext,
        ResourceMenuViewAccessDecisionManager,

        ApplicationService,
        GeoService,
        BlackListService,
        IdBlackListService,
        WhiteListService,
        SecurityLinkService,
        RoleService,
        UserService,
        CampaignService,
        OfferService,
        WebsiteService,
        AccessControlListService,
        PostbackRouteRuleService,
        PushTemplateService,
        PushScheduleTemplateService,
        PushService,
        AccessLogService,
        PostbackRoutingLogService,
        PostbackLogService,
        ClickService,
        EventService,
        VersionService,
        SettingService,
        PushSetService,
        DomainService,

        StatusPipe,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
