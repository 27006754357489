import {Geo} from './geo';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiService} from '../../../shared/service/api.service';
import {GeoPageItem} from './geo-page-item';

@Injectable()
export class GeoService extends ApiService<GeoPageItem, Geo> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'geo';
    }
}
