import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../../shared/service/api.service';
import {BlackListPageItem} from './black-list-page-item';
import {BlackList} from './black-list';
import {Observable} from 'rxjs';
import {FilterResponseInterface} from '../../../../shared/interfaces/filter-response.interface';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class BlackListService extends ApiService<BlackListPageItem, BlackList> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }
    public filterRowData(data: string): Observable<FilterResponseInterface<BlackListPageItem>[]> {
        return this.httpClient.get<FilterResponseInterface<BlackListPageItem>[]>(`${environment.API_ROOT_PATH}black_list_by_ip/${data}`, {});
    }

    protected resourcePath(): string {
        return 'black_list';
    }
}
