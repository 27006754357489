import {Injectable} from '@angular/core';
import {WhiteList} from './white-list';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '../../../shared/service/api.service';
import {WhiteListPageItem} from './white-list-page-item';

@Injectable()
export class WhiteListService extends ApiService<WhiteListPageItem, WhiteList> {

    constructor(httpClient: HttpClient) {
        super(httpClient);
    }

    protected resourcePath(): string {
        return 'white_list';
    }

    // public changeState(id: number, vpn: boolean = false): Observable<{}> {
    //     return this.httpClient.get(this.getRootPath() + `${this.getBaseUrl()}/${id}/${vpn ? 'vpn' : 'active'}-switch`);
    // }
}
